import apiSlice from "./apiSlice";


export const homeSlice = apiSlice.injectEndpoints({
    overrideExisting:true,
    endpoints:builder =>({
        getMenuItems:builder.query({
            query:({}) =>({
                url:'/getMenuItems',
                method:'GET',
                //credentials:'include'
            })
        }),
        userSignUp:builder.mutation({
            query:({
                firstname='',lastname='',domain_name='',email_id='',password='',
            })=>({
                url:'/UserSignUp',
                method:'POST',
                body:{
                    firstname:firstname,
                    lastname:lastname,
                    domain_name:domain_name,
                    email_id:email_id,
                    password:password
                }
            })
        }),

        userLogin : builder.mutation({
            query:({email_id='',password='',captchatoken=''})=>({
                url: '/UserLogin',
                method:'POST',
                body:{
                    email_id:email_id,
                    password:password,
                    captchatoken:captchatoken
                },
                //credentials:'include'
            }), 
        }),
        getHomeData: builder.query({
            query:({})=>({
                url:'/getHomeData',
                method:'GET'
            })
        }),
        getCountryList:builder.query({
            query:() =>({
                url:'/getCountryList',
                method:'GET'
            })
        }),
        getStateByCountryId: builder.query({
            query:({id=''}) =>({
                url:`/getStateListByCountryId/${id}`,
                method:'GET'
            })
        }),
        getCitiesListByStateId:builder.query({
            query:({id=''}) =>({
                url:`/getCitiesListByStateId/${id}`,
                method:'GET'
            })
        }),
        getCountryNameById:builder.query({
            query:({id='',domain_name=''}) =>({
                url:`/getCountryNameById/${id}?customerName=${domain_name}`,
                method:'GET'
            })
        }),
        getStateNameById:builder.query({
            query:({id='',domain_name=''}) =>({
                url:`/getStateNameById/${id}?customerName=${domain_name}`,
                method:'GET'
            })
        }),
        getCityNameById:builder.query({
            // query:({id='',domain_name=''}) =>({
            //     url:`/getCityNameById/${id}?customerName=${domain_name}`,
            //     method:'GET'
            // })
            query: ({ id, domain_name }) => ({
                url: `/getCityNameById/${id || 0}?customerName=${domain_name}`,
                method: 'GET'
              })
              
        }),
        sendMail:builder.mutation({
            query:({data=''})=>({
                url:`/sendEmail`,
                method:'POST',
                body:data
            })
        }),
        verifyOTP:builder.mutation({
            query:({data=''})=>({
                url:'/verifyOTP',
                method:'POST',
                body:data
            })
        }),
        getCurrency:builder.query({
            query:()=>({
                url:`/getCurrency`,
                method:'GET'
            })
        }),
        getTimeZone:builder.query({
            query:()=>({
                url:'/getTimezones',
                method:'GET'
            })
        }),
        getThirdPartyTokens:builder.query({
            query:({})=>({
                url:'/getThirdPartyTokens',
                method:'GET'
            })
        })
    })
})


export const {
    useGetMenuItemsQuery, useUserSignUpMutation, useUserLoginMutation,useGetHomeDataQuery, useGetCountryListQuery, useGetCitiesListByStateIdQuery, 
    useGetStateByCountryIdQuery,
    useGetCountryNameByIdQuery,
    useGetStateNameByIdQuery,
    useGetCityNameByIdQuery, useSendMailMutation,
    useVerifyOTPMutation, useGetCurrencyQuery, useGetTimeZoneQuery, useGetThirdPartyTokensQuery
} = homeSlice;