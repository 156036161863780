import React, { useEffect, useState } from "react";
import { useGetSMTPConfigQuery, useSendTestEmailMutation, useUpdateSMTPMutation } from "../../../../Redux/API/atsSlice";
import { useSelector } from "react-redux";
import './SMTPsetting.css'
import { showToast } from "../../../ToastContainer/ToastProvider";



const SMTPSettings = () =>{
  const isAuth = useSelector((state) => state.auth);
  const [testemail,settestemail] = useState({
    to_email:""
  })
  const [smtp,setsmtp] = useState([
    {
      "label": "SMTP Host",
      "type": "text",
      "placeholder": "e.g., smtp.gmail.com",
      "value": "",
      "id":"smtp_host"
    },
   {
      "label": "SMTP Port",
      "type": "number",
      "placeholder": "e.g., 465 or 587",
      "value": "",
      "id":"smtp_port"
    },
    {
      "label": "Secure Connection (SSL/TLS)",
      "type": "checkbox",
      "options": [
        { "label": "Yes", "value": true },
        { "label": "No", "value": false }
      ],
      "value": "",
      "id":"secure_connection"
    },
    {
      "label": "Username (Email Address)",
      "type": "email",
      "placeholder": "e.g., your-email@example.com",
      "value": "",
      "id":"username"
    },
    {
      "label": "Password",
      "type": "password",
      "note": "Stored securely or use an app-specific password.",
      "value": "",
      "id":"password"
    },
    {
      "label": "Ignore TLS Certificate Errors",
      "type": "checkbox",
      "value": true,
      "id":"tls_ignore_errors"
    },
    {
      "label": "From Name (optional)",
      "type": "text",
      "placeholder": "e.g., John Doe",
      "value": "",
      "id":"from_name"
    },
    {
      "label": "From Email (optional)",
      "type": "email",
      "placeholder": "e.g., contact@logiglo.com",
      "value": "",
      "id":"from_email"
    }
  
  ])
  const {
    data, isSuccess, refetch
  } = useGetSMTPConfigQuery({
    domain_name:isAuth?.user?.domain_name,
    userid:isAuth?.user?.user_id
  })


  useEffect(()=>{
    refetch()
    if(data?.status){
       setsmtp((prev)=>{
          const previousData = [...prev]
          const updatedData = previousData.map((item, i) => ({
            ...item,
            value: data?.message?.[0]?.[item?.id]
          }));
        //  console.log('updated',data?.message?.[0]?.['user_id'])
          return updatedData
       })
       console.log("data",data)
    }
  },[isSuccess,data])
      const [update] = useUpdateSMTPMutation()
       
      const updateSMTP = async() =>{
        const obj = {

        }
        smtp.forEach((item,i)=>{
          if(item?.type=='checkbox'){
            obj[item?.id] = item?.value===true?1:item?.value===false?0:typeof item?.value==="string"?parseInt(item?.value):item?.value
          }else{
              obj[item?.id]=item?.value
          }
        })
       // console.log(obj)
        try{
          const res = await update({
              domain_name:isAuth?.user?.domain_name,
              userid:isAuth?.user?.user_id,
              data:obj
          })
          if(res)
                showToast(res?.data?.status,res?.data?.message)
          // console.log("res",res)
        }catch(err){
          showToast(err?.data?.status,err?.data?.message)
        }
      }
      const [sendTest] = useSendTestEmailMutation()
      const handleTest = async () =>{
        try{
          const res = await sendTest({
            domain_name:isAuth?.user?.domain_name,
            userid:isAuth?.user?.user_id,
            email_data:testemail
          })
          if(res)
            showToast(res?.data?.status,res?.data?.message)
          settestemail({
            to_email: ""
          });
          // console.log("res",res)
        }catch(err){
          showToast(err?.data?.status,err?.data?.message);
          settestemail({
            to_email: ""
          });
        }
      }
    // console.log("smtp",smtp)
    return(
        <div className="setting-smtp-control-wrapper">
        {smtp.map((item, i) => {
          const isCheckbox = item?.type === "checkbox";
          const checkedValue =
            isCheckbox
              ? item?.value === "1" || item?.value === 1
                ? true
                : item?.value === "0" || item?.value === 0
                  ? false
                  : item?.value
              : undefined;
      
          return (
            <div className="setting-smtp-control-field" key={i}>
              <label className="setting-smtp-control-label">{item.label}</label>
              <input
                className="setting-smtp-control-input"
                checked={isCheckbox ? checkedValue : undefined}
                value={!isCheckbox ? item.value : undefined}
                type={item?.type}
                placeholder={item?.placeholder}
                onChange={(e) => {
                  if (isCheckbox) {
                    setsmtp((prev) =>
                      prev.map((field, idx) =>
                        i === idx ? { ...field, value: e.target.checked } : field
                      )
                    );
                  } else {
                    setsmtp((prev) =>
                      prev.map((field, idx) =>
                        i === idx ? { ...field, value: e.target.value } : field
                      )
                    );
                  }
                }}
              />
            </div>
          );
        })}
      
        <button className="setting-smtp-control-update-btn" onClick={updateSMTP}>
          Update
        </button>
      
        {data?.message?.[0]?.username && (
          <div className="setting-smtp-control-test-mail">
            <p>
              From Mail:{" "}
              {data.message?.[0]?.from_email
                ? data.message?.[0]?.from_email
                : data.message?.[0]?.username}
            </p>
            
            <input
              className="setting-smtp-control-input"
              type="email"
              placeholder="Enter test email"
              value={testemail.to_email}
              onChange={(e) =>
                settestemail({ ...testemail, to_email: e.target.value })
              }
            />
            <button
              className="setting-smtp-control-send-btn"
              onClick={handleTest}
            >
              Send Test Email
            </button>
          </div>
        )}
      </div>
      
    )
}

export default SMTPSettings