import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useDeleteInterviewMutation, useGetcustomerInterviewsQuery } from '../../Redux/API/atsSlice';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import usePrivilege from '../Privileges/Privileges';
import CommonTable from '../CommonTable/CommonTable';
import { IoDocumentTextOutline } from "react-icons/io5";
import { FaRegEdit, FaTrashAlt } from "react-icons/fa";
import { MdOutlineDeleteOutline } from "react-icons/md";
import { RiDeleteBin5Line } from "react-icons/ri";
import { CiSquarePlus } from "react-icons/ci";

const Interview = () => {
  const [interviewData, setInterviewData] = useState([]);
    const [showConfirm, setShowConfirm] = useState(false);
  const isAuth = useSelector((state) => state.auth);
  const location = useLocation()
  const [params] = useSearchParams()
 
  const navigate=useNavigate()

  const { data, isSuccess, refetch } = useGetcustomerInterviewsQuery({
    domain_name: isAuth?.user?.domain_name,
    role_id:isAuth?.user?.role_id,
    user_id:isAuth?.user?.user_id
  });
console.log("interviewwww", data);

  const isInterviewUpdate = usePrivilege('Interview Update')
  useEffect(() => {
    refetch();
  }, [refetch]);


  function getCompletedInterviews(interviews) {
    const now = new Date();

    const isValidDate = (dateStr) => {
     const d = new Date(dateStr);
     return !isNaN(d.getTime());
    };

    return interviews
     ?.filter(item => isValidDate(item.interview_date))
     ?.filter(item => new Date(item.interview_date) < now)
     ?.sort((a, b) => new Date(a.interview_date) - new Date(b.interview_date));
}


function getInterviewsThisWeek(interviews) {
    const today = new Date();
    const day = today.getDay(); // Sunday = 0, Monday = 1, ..., Saturday = 6
    const diffToMonday = (day === 0 ? -6 : 1 - day);
    const startOfWeek = new Date(today);
    startOfWeek.setDate(today.getDate() + diffToMonday);
    startOfWeek.setHours(0, 0, 0, 0);

    const endOfWeek = new Date(startOfWeek);
    endOfWeek.setDate(startOfWeek.getDate() + 6);
    endOfWeek.setHours(23, 59, 59, 999);

    const isValidDate = (date) => {
     const d = new Date(date);
     return !isNaN(d.getTime());
    };

    return interviews
     ?.filter(item => isValidDate(item.interview_date))
     ?.filter(item => {
        const date = new Date(item.interview_date);
        return date >= startOfWeek && date <= endOfWeek;
     })
     ?.sort((a, b) => new Date(a.interview_date) - new Date(b.interview_date));
}


useEffect(() => {
  // if (isSuccess) {
  // setInterviewData(data?.message || []);
  // }
  if(params.get('filter')==='this-week' && isSuccess){
   setInterviewData(getInterviewsThisWeek(data?.message))
  }else if(params.get("filter")==="completed-interviews" && isSuccess){
   setInterviewData(getCompletedInterviews(data?.message))
  }else{
      if(location?.state){
   setInterviewData(location?.state?.value)
  }else if(isSuccess){
   setInterviewData(data?.message || []);
  }
  }
  // if(location?.state){
  // setInterviewData(location?.state?.value)
  // }else if(isSuccess){
  // setInterviewData(data?.message || []);
  // }
}, [isSuccess, data, location,params]);

  // useEffect(() => {
  //   // if (isSuccess) {
  //   //   setInterviewData(data?.message || []);
  //   // }
  //   if(location?.state){
  //     setInterviewData(location?.state?.value)
  //   }else if(isSuccess){
  //     setInterviewData(data?.message || []);
  //   }
  // }, [isSuccess, data, location]);

  // console.log("interviewss===>",interviewData);
  
  const columns = [
    { key: "interview_id", label: "Interview ID" },
    { key: "interview_for", label: "Interview For" },
    { key: "interview_date", label: "Interview Date" },
    { key: "interview_stage", label: "Interview Stage" },
    { key: "interviewer", label: "Interviewer" },
    { key: "location", label: "Location" },
    {key:"created_at", label:"Created At"},
   

  ];

  // const handleRowAction = (job) => {
  //   navigate(`?type=job_openings&id=${job.job_id}`);
  // };


  console.log("......",interviewData)
  const [deleteInterview]=useDeleteInterviewMutation();
  const handleDelete = async (e,interview_id) => {
    e.preventDefault()
    const obj = {
      id:interview_id
    }
    try{
      const res = await deleteInterview({
        domain_name:isAuth?.user?.domain_name,
        data:obj
      })
      setShowConfirm(false)
      if(res?.data?.status){
        refetch()
      }
      console.log("res",res)
    }catch(err){

    }
}

  const handleRowAction = (interviewData) => (
    
    
    <>
      {/* <button 
        onClick={() => navigate(`?type=job_openings&id=${job.job_id}`)}
        className="commonTable-dropdown-item"
      >
        <IoDocumentTextOutline /> View Interview Details
      </button> */}
      <button 
        // onClick={()=>navigate(`EditJob/${job.job_id}`)}
        // disabled={!isInterviewUpdate.includes(isAuth?.user?.user_id)}
        onClick={() => navigate(`/ats/dashboard/EditInterview/${interviewData.interview_id}`)}
        className="commonTable-dropdown-item"
      >
        <FaRegEdit /> Edit Interview
      </button>
      <button
      className="commonTable-dropdown-item"
       onClick={() => setShowConfirm(true)}>
       <MdOutlineDeleteOutline/> Delete Interview
      </button>

      {showConfirm && (
        <div className="popup-overlay">
          <div className="popup-content">
            <p>Are you sure you want to delete this job?</p>
            <div className="popup-actions">
              <button  onClick={(e)=>handleDelete(e,interviewData?.interview_id)}>
                proceed
              </button>
              <button onClick={() => setShowConfirm(false)}>Cancel</button>
            </div>
          </div>
        </div>
      )}
      {/* <button 
        onClick={() => console.log("Delete job:", job.job_id)}
        className="commonTable-dropdown-item danger"
      >
        <RiDeleteBin5Line/> Delete Interview
      </button> */}
    </>
  );

  const sidebarLabels = [
"All Interviews",
"Active Interviews",
"Inactive Interviews",
"Recent Interviews",
"My Interviews"
  ];

  const [selectedFilter, setSelectedFilter] = useState("All Interviews");
  const [sortOrder, setSortOrder] = useState("Newest First");
  const [selectedColumns, setSelectedColumns] = useState([
    "interview_id", "interview_for", "interview_date", "interview_stage", "interviewer","location","created_at"
  ]);
  
  const getFilteredInterviewData = () => {
    let filteredInterviewData = [...interviewData];
  
    if (selectedFilter === "Active Candidates") {
      filteredInterviewData = interviewData.filter(candidate => candidate.status === "Active");
    } else if (selectedFilter === "Inactive Candidates") {
      filteredInterviewData = interviewData.filter(candidate => candidate.status === "Inactive");
    } else if (selectedFilter === "Recent Candidates") {
      filteredInterviewData = interviewData.slice().sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
    }
  
    const getParsedDate = (dateString) => {
      if (!dateString) return new Date(0);
      return new Date(dateString.replace("at", ""));
    };
  
    if (sortOrder === "Newest First") {
      filteredInterviewData.sort((a, b) => getParsedDate(b.created_at) - getParsedDate(a.created_at));
    } else if (sortOrder === "Oldest First") {
      filteredInterviewData.sort((a, b) => getParsedDate(a.created_at) - getParsedDate(b.created_at));
    }
  
    return filteredInterviewData;
  };
  
   
  return (
    <div>
      {/* <table border="1" cellPadding="10" cellSpacing="0">
        <thead>
          <tr>
            <th>Interview ID</th>
            <th>Candidate Name</th>
            <th>Position</th>
            <th>Date</th>
            <th>Stage</th>
            <th>Interviewer(s)</th>
            <th>Created At</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {interviewData.length > 0 ? (
            interviewData.map((interview) => (
              <tr key={interview.interview_id}>
                <td>{interview.interview_id}</td>
                <td>{interview.candidate_name}</td>
                <td>{interview.interview_for}</td>
                <td>{new Date(interview.interview_date).toLocaleString()}</td>
                <td>{interview.interview_stage}</td>
                <td>
                  {interview.interviewer !== 'Not Found'
                    ? JSON.parse(interview.interviewer)
                        .map((i) => i.label)
                        .join(', ')
                    : 'Not Assigned'}
                </td>
                <td>{interview.created_at}</td>
                <td>
                  <button disabled={!isInterviewUpdate.includes(isAuth?.user?.user_id)} onClick={() => navigate(`/ats/dashboard/EditInterview/${interview.interview_id}`)}>
                    Edit
                  </button>
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="7" style={{ textAlign: 'center' }}>
                No interviews available
              </td>
            </tr>
          )}
        </tbody>
      </table> */}
      <CommonTable
      module_name={"interviews"}
      headerButton="Schedule Interview"
      headerIcon={<CiSquarePlus/>}
      headerNavigateTo="/ats/dashboard/?type=createInterviews"
      columns={columns}
      // data={interviewData}
      meta_title={"Interviews"}
      data={getFilteredInterviewData()}
      onRowAction={handleRowAction}
      title={selectedFilter}
      sidebarLabels={sidebarLabels}
      rowIdentifier='interview_id'
      privilege={"Interview Create"}
      onFilterChange={setSelectedFilter}
      onSortChange={setSortOrder}
      selectedColumns={selectedColumns} 
      onColumnChange={setSelectedColumns}
      />
    </div>
  );
};

export default Interview;
