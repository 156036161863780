
import React, { useState, useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import PersonalSettingsForm from "./SubSettings/PersonalSetting/PersonalSetting";
import "./Settings.css";
import ChangePrefix from "./SubSettings/ChangePrefix/ChangePrefix";
import EmbededCode from "./SubSettings/EmbededCode/EmbededCode";
import Role from "./SubSettings/Roles/Roles";
import Reportees from "./SubSettings/Reportees/Reportees";
import CreateUser from "./SubSettings/UserCreation/CreateUser";
import CreateGroups from "./SubSettings/Groups/CreateGroups";
import CompanyDetailForm from "./SubSettings/CompanyDetailForm/CompanyDetailForm";
import { FaSearch } from "react-icons/fa";
import CandidateSettings from "./SubSettings/ModuleSettings/CandidateSetting";
import LoginSettings from "./SubSettings/ModuleSettings/LoginSettings";
import DashBoardSettings from "./SubSettings/ModuleSettings/DashBoardSettings";
import { useSelector } from "react-redux";
import SMTPSettings from "./SubSettings/ModuleSettings/SMTPSetting";
import SettingDashboard from "./SettingDashboard/SettingDashboard";

const Settings = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [searchSettings, setSearchSettings] = useState("");
  const [activeSetting, setActiveSetting] = useState("setting-dashboard");
  const isAuth = useSelector((state) => state.auth);

  useEffect(() => {
    const newSubsetting = searchParams.get("subsetting") || "setting-dashboard";
    setActiveSetting(newSubsetting);
  }, [searchParams]);

  const settingsData = [
    {
      title: "General",
      items: [
        { name: "Personal Settings", key: "personal-settings" },
        { name: "Company Details", key: "company-details" },
      ],
    },
    {
      title: "Advanced",
      items: [{ name: "Change Prefix", key: "change-prefix" }],
    },
    {
      title: "Embedded Code",
      items: [{ name: "Edit Style", key: "edit-style" }],
    },
    {
      title: "Roles & Privileges",
      items: [
        { name: "Role", key: "role" },
        { name: "User Management", key: "user-management" },
        { name: "Create Groups", key: "create-groups" },
      ],
    },
    {
      title: "Hierarchy",
      items: [{ name: "Reportees and Dotted Line Managers", key: "hierarchy" }],
    },
    {
      title: "Setup",
      items: [
        { name: "Candidate Settings", key: "candidate-settings" },
        { name: "Login Settings", key: "login-settings" },
        { name: "Dashboard Settings", key: "dashboard-settings" },
        { name: "SMTP Settings", key: "smtp-settings" },
      ],
    },
  ];

  const filteredData = settingsData.filter((category) =>
    category.title.toLowerCase().includes(searchSettings.toLowerCase())
  );

  function renderSettingsContent() {
    switch (activeSetting) {
      case "personal-settings":
        return <PersonalSettingsForm />;
      case "setting-dashboard":
        return (
          <SettingDashboard
            onItemClick={(key) => {
              setActiveSetting(key);
              navigate(`?type=settings&subsetting=${key}`, { replace: true });
              window.scrollTo({ top: 0, behavior: "smooth" });
            }}
          />
        );
      case "company-details":
        return <CompanyDetailForm />;
      case "change-prefix":
        return <ChangePrefix />;
      case "edit-style":
        return <EmbededCode />;
      case "role":
        return <Role />;
      case "user-management":
        return <CreateUser />;
      case "create-groups":
        return <CreateGroups />;
      case "hierarchy":
        return <Reportees />;
      case "candidate-settings":
        return (
          <>
            {isAuth?.user?.role_id === "WR0004" && <CandidateSettings />}
          </>
        );
      case "login-settings":
        return (
          <>
            {isAuth?.user?.role_id === "WR0004" && <LoginSettings />}
          </>
        );
      case "dashboard-settings":
        return (
          <>
            {isAuth?.user?.role_id === "WR0004" && <DashBoardSettings />}
          </>
        );
      case "smtp-settings":
        return <SMTPSettings />;
      default:
        return <div>Select a setting to edit.</div>;
    }
  }

  return (
    <div className="settings-container">
      <div className="search-setting-header">
        <h2 className="settings-title">Account Settings</h2>
        <div className="search-box">
          <FaSearch className="search-icon" />
          <input
            type="text"
            placeholder="Search by title"
            className="settings-search"
            value={searchSettings}
            onChange={(e) => setSearchSettings(e.target.value)}
          />
        </div>
      </div>
      <div className="settings-layout">
        <div className="settings-sidebar">
          {filteredData.map((category, index) => (
            <div key={index} className="settings-category">
              <h4 className="category-title">{category.title}</h4>
              <ul>
                {category.items.map((item, itemIndex) => (
                  <li key={itemIndex}>
                    <button
                      className={activeSetting === item.key ? "active-link" : ""}
                      onClick={() => {
                        setActiveSetting(item.key);
                        navigate(`?type=settings&subsetting=${item.key}`, { replace: true });
                        window.scrollTo({ top: 0, behavior: "smooth" });
                      }}
                    >
                      {item.name}
                    </button>
                  </li>
                ))}
              </ul>
            </div>
          ))}
        </div>
        <div className="settings-content">{renderSettingsContent()}</div>
      </div>
    </div>
  );
};

export default Settings;
