import React, { useEffect, useState } from "react";
import { useGetPrefixDataQuery, useUpdatePrefixMutation } from "../../../../Redux/API/atsSlice";
import { useSelector } from "react-redux";
import "./ChangePrefix.css";
import {showToast} from "../../../ToastContainer/ToastProvider"

const ChangePrefix = () => {
  const isAuth = useSelector((state) => state.auth);
  const [input, setInput] = useState([]);
  const [prefixData, setPrefixData] = useState([]);
  const [selectedOption, setSelectedOption] = useState("");
  const [selectedEntity, setSelectedEntity] = useState("");

  const [updatePrefix] = useUpdatePrefixMutation({});
  
  const { data: Prefix, isSuccess: PrefixDataSuccess, refetch: PrefixDataRefetch } = useGetPrefixDataQuery({
    domain_name: isAuth?.user?.domain_name,
  });

  console.log("Prefix",Prefix?.message)
  useEffect(() => {
    if (Prefix?.status) {
      setPrefixData(Prefix?.message);
    }
  }, [PrefixDataSuccess]);

  useEffect(() => {
    if (selectedOption && prefixData.length > 0) {
      const selectedItem = prefixData.find((item) => item?.id === parseInt(selectedOption));
      if (selectedItem) {
        setInput([{ ...selectedItem }]);
        setSelectedEntity(selectedItem.module_name || "");
      }
    }
  }, [selectedOption, prefixData]);

  const handleUpdate = async (e) => {
    e.preventDefault();
    try {
      const response = await updatePrefix({
        domain_name: isAuth?.user?.domain_name,
        data: input.length > 0 ? input[0] : { prefix: " ", id: 0 },
      });

      if (response.data.status) {
        // console.log(response.data)
        showToast(response.data.status,response.data.message)
        setInput();
      }
      // console.log("response", response);
    } catch (err) {
      console.log(err);
    }
  };
  const [format,setformat] = useState([])
  useEffect(()=>{
    setformat(Prefix?.message?.find((item,i)=>item?.module_name===selectedEntity))
  },[selectedEntity,selectedOption])
  return (
    <>
    <h3 style={{padding:'0 0 0 20px'}}>Change Prefix</h3>
    <div className="change-prefix-container">
      <div className="change-prefix-form-group">
        <div className="change-prefix-field">
          <label htmlFor="change-prefix-dropdown">Select Entity</label>
          <select id="change-prefix-dropdown" onChange={(e) => setSelectedOption(e.target.value)} value={selectedOption}>
            {prefixData && [{ id: 0, module_name: "Select an Option" }, ...prefixData].map((item) => (
              <option key={item.id} value={item?.id}>
                {item?.module_name}
              </option>
            ))}
          </select>
        </div>

        <div className="change-prefix-field">
          <label>Selected Entity</label>
          <input type="text" value={selectedEntity} readOnly className="change-prefix-selected-entity" />
        </div>
      </div>

      <div className="change-prefix-field">
        <label>Prefix Format</label>
        <input
          maxLength={4}
          onChange={(e) => setInput([{ ...input[0], prefix: e.target.value }])}
          value={input?.length>0?input[0]?.prefix:""}
          className="change-prefix-input"
          type="text"
        />
      </div>
      {selectedEntity &&
          <>
           <p>Current Format: {format?.prefix}{format?.identifier[0]}</p>
           <p>Updated Format: {input[0]?.prefix}{(parseInt(format?.identifier[0]) + 1).toString().padStart(4, '0')}</p>
          </>
      }
       
      <div className="change-prefix-button-group">
        <button className="change-prefix-cancel-button">Cancel</button>
        <button className="change-prefix-save-button" onClick={handleUpdate}>Save changes</button>
      </div>
    </div>
    </>
  );
};

export default ChangePrefix;


