import React from "react";
import SingleSettingCard from "./SingleSettingCard";
import "./SettingDashboard.css";

const SettingDashboard = ({ onItemClick }) => {
  const settingsData = [
    {
      title: "General",
      desc: "Manage your organization's details, including name, website, description, and contact information.",
      items: [
        { name: "Personal Settings", key: "personal-settings" },
        { name: "Company Details", key: "company-details" },
      ],
    },
    {
      title: "Advanced",
      desc: "Fine-tune your organization's settings for better customization and workflow efficiency.",
      items: [{ name: "Change Prefix", key: "change-prefix" }],
    },
    {
      title: "Embedded Code",
      items: [{ name: "Edit Style", key: "edit-style" }],
    },
    {
      title: "Roles & Privileges",
      desc: "Define and manage user roles, permissions, and access levels within the organization.",
      items: [
        { name: "Role", key: "role" },
        { name: "User Management", key: "user-management" },
        { name: "Create Groups", key: "create-groups" },
      ],
    },
    {
      title: "Hierarchy",
      desc: "Define and manage the structural flow of your organization for clear reporting and role assignments.",
      items: [{ name: "Reportees and Dotted Line Managers", key: "hierarchy" }],
    },
    {
      title: "Setup",
      desc: "Configure essential system settings for seamless recruitment management.",
      items: [
        { name: "Candidate Settings", key: "candidate-settings" },
        { name: "Login Settings", key: "login-settings" },
        { name: "Dashboard Settings", key: "dashboard-settings" },
        { name: "SMTP Settings", key: "smtp-settings" },
      ],
    },
  ];

  return (
    <div className="setting-dashboard">
      {settingsData.map((setting, index) => (
        <SingleSettingCard
          key={index}
          title={setting.title}
          desc={setting.desc}
          items={setting.items}
          onItemClick={onItemClick}
        />
      ))}
    </div>
  );
};

export default SettingDashboard;
