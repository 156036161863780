import React from "react";
import "./SingleSettingCard.css";

const SingleSettingCard = ({ title, desc, items, onItemClick }) => {
  return (
    <div className="single-setting-card">
      <div className="setting-main-heading">{title}</div>
      {desc && <div className="setting-main-description">{desc}</div>}
      <div className="setting-main-items">
        {items.map((item) => (
          <div
            className="setting-main-page"
            key={item.key}
            onClick={() => onItemClick && onItemClick(item.key)}
          >
            {item.name}
          </div>
        ))}
      </div>
    </div>
  );
};

export default SingleSettingCard;
